@font-face {
    font-family: "tpticon";
    font-weight: normal;
    font-style: normal;
    src: url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.4p9d73zkvs.eot);
    src: url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.4p9d73zkvs.eot) format("embedded-opentype"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.rk6kwbejyx.woff2) format("woff2"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.mvyyj37tmh.woff) format("woff"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.qc6pdhje4y.ttf) format("truetype"), url(https://static1.teacherspayteachers.com/tpt-frontend/releases/production/current/tpticon.ebxzn1n9j7.svg) format("svg"); }

.tpt-frontend .tpticon {
  display: inline-block;
  font-family: tpticon;
  line-height: 1;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.tpt-frontend .tpticon-lg {
  font-size: 1.33333em;
  line-height: 0.75em;
  vertical-align: -15%; }

.tpt-frontend .tpticon-2x {
  font-size: 2em; }

.tpt-frontend .tpticon-3x {
  font-size: 3em; }

.tpt-frontend .tpticon-4x {
  font-size: 4em; }

.tpt-frontend .tpticon-fw {
  width: 1.28571429em;
  text-align: center; }

.tpt-frontend .tpticon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none; }

.tpt-frontend .tpticon-ul > li {
  position: relative; }

.tpt-frontend .tpticon-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center; }

.tpt-frontend .tpticon-li.tpticon-lg {
  left: -1.85714286em; }

.tpt-frontend .tpticon-border {
  padding: .2em .25em .15em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em; }

.tpt-frontend .tpticon-pull-left {
  float: left; }

.tpt-frontend .tpticon-pull-right {
  float: right; }

.tpt-frontend .tpticon.tpticon-pull-left {
  margin-right: 0.3em; }

.tpt-frontend .tpticon.tpticon-pull-right {
  margin-left: 0.3em; }

.tpt-frontend .tpticon-angle-down::before {
  content: "\ea01"; }

.tpt-frontend .tpticon-angle-left::before {
  content: "\ea02"; }

.tpt-frontend .tpticon-angle-right::before {
  content: "\ea03"; }

.tpt-frontend .tpticon-angle-up::before {
  content: "\ea04"; }

.tpt-frontend .tpticon-archive::before {
  content: "\ea05"; }

.tpt-frontend .tpticon-area-chart::before {
  content: "\ea06"; }

.tpt-frontend .tpticon-balance-scale::before {
  content: "\ea07"; }

.tpt-frontend .tpticon-bookmark::before {
  content: "\ea08"; }

.tpt-frontend .tpticon-bookmark-o::before {
  content: "\ea09"; }

.tpt-frontend .tpticon-calendar::before {
  content: "\ea0a"; }

.tpt-frontend .tpticon-calendar-check-o::before {
  content: "\ea0b"; }

.tpt-frontend .tpticon-caret-down::before {
  content: "\ea0c"; }

.tpt-frontend .tpticon-caret-right::before {
  content: "\ea0d"; }

.tpt-frontend .tpticon-caret-up::before {
  content: "\ea0e"; }

.tpt-frontend .tpticon-chart-line-minimal::before {
  content: "\ea0f"; }

.tpt-frontend .tpticon-check::before {
  content: "\ea10"; }

.tpt-frontend .tpticon-check-circle::before {
  content: "\ea11"; }

.tpt-frontend .tpticon-check-circle-o::before {
  content: "\ea12"; }

.tpt-frontend .tpticon-chevron-left::before {
  content: "\ea13"; }

.tpt-frontend .tpticon-chevron-right::before {
  content: "\ea14"; }

.tpt-frontend .tpticon-circle::before {
  content: "\ea15"; }

.tpt-frontend .tpticon-circle-thin::before {
  content: "\ea16"; }

.tpt-frontend .tpticon-clipboard::before {
  content: "\ea17"; }

.tpt-frontend .tpticon-clock-o::before {
  content: "\ea18"; }

.tpt-frontend .tpticon-close::before {
  content: "\ea19"; }

.tpt-frontend .tpticon-cloud-download::before {
  content: "\ea1a"; }

.tpt-frontend .tpticon-cog::before {
  content: "\ea1b"; }

.tpt-frontend .tpticon-comment-o::before {
  content: "\ea1c"; }

.tpt-frontend .tpticon-commenting::before {
  content: "\ea1d"; }

.tpt-frontend .tpticon-comments-o::before {
  content: "\ea1e"; }

.tpt-frontend .tpticon-copy::before {
  content: "\ea1f"; }

.tpt-frontend .tpticon-credit-card::before {
  content: "\ea20"; }

.tpt-frontend .tpticon-database::before {
  content: "\ea21"; }

.tpt-frontend .tpticon-download::before {
  content: "\ea22"; }

.tpt-frontend .tpticon-ellipsis-v::before {
  content: "\ea23"; }

.tpt-frontend .tpticon-envelope-o::before {
  content: "\ea24"; }

.tpt-frontend .tpticon-exchange::before {
  content: "\ea25"; }

.tpt-frontend .tpticon-exclamation-triangle::before {
  content: "\ea26"; }

.tpt-frontend .tpticon-external-link::before {
  content: "\ea27"; }

.tpt-frontend .tpticon-eye::before {
  content: "\ea28"; }

.tpt-frontend .tpticon-facebook::before {
  content: "\ea29"; }

.tpt-frontend .tpticon-facebook-square::before {
  content: "\ea2a"; }

.tpt-frontend .tpticon-file::before {
  content: "\ea2b"; }

.tpt-frontend .tpticon-file-o::before {
  content: "\ea2c"; }

.tpt-frontend .tpticon-file-text-o::before {
  content: "\ea2d"; }

.tpt-frontend .tpticon-flag-o::before {
  content: "\ea2e"; }

.tpt-frontend .tpticon-folder-open-o::before {
  content: "\ea2f"; }

.tpt-frontend .tpticon-github::before {
  content: "\ea30"; }

.tpt-frontend .tpticon-globe::before {
  content: "\ea31"; }

.tpt-frontend .tpticon-grades::before {
  content: "\ea32"; }

.tpt-frontend .tpticon-graduation-cap::before {
  content: "\ea33"; }

.tpt-frontend .tpticon-heart::before {
  content: "\ea34"; }

.tpt-frontend .tpticon-heart-o::before {
  content: "\ea35"; }

.tpt-frontend .tpticon-home::before {
  content: "\ea36"; }

.tpt-frontend .tpticon-info-circle::before {
  content: "\ea37"; }

.tpt-frontend .tpticon-instagram::before {
  content: "\ea38"; }

.tpt-frontend .tpticon-laptop::before {
  content: "\ea39"; }

.tpt-frontend .tpticon-lightbulb-o::before {
  content: "\ea3a"; }

.tpt-frontend .tpticon-line-chart::before {
  content: "\ea3b"; }

.tpt-frontend .tpticon-link::before {
  content: "\ea3c"; }

.tpt-frontend .tpticon-linkedin::before {
  content: "\ea3d"; }

.tpt-frontend .tpticon-list-ul::before {
  content: "\ea3e"; }

.tpt-frontend .tpticon-lock::before {
  content: "\ea3f"; }

.tpt-frontend .tpticon-long-arrow-right::before {
  content: "\ea40"; }

.tpt-frontend .tpticon-magic::before {
  content: "\ea41"; }

.tpt-frontend .tpticon-money::before {
  content: "\ea42"; }

.tpt-frontend .tpticon-newspaper-o::before {
  content: "\ea43"; }

.tpt-frontend .tpticon-paper-plane::before {
  content: "\ea44"; }

.tpt-frontend .tpticon-pencil::before {
  content: "\ea45"; }

.tpt-frontend .tpticon-pencil-square-o::before {
  content: "\ea46"; }

.tpt-frontend .tpticon-phone::before {
  content: "\ea47"; }

.tpt-frontend .tpticon-pinterest-p::before {
  content: "\ea48"; }

.tpt-frontend .tpticon-pinterest-square::before {
  content: "\ea49"; }

.tpt-frontend .tpticon-play::before {
  content: "\ea4a"; }

.tpt-frontend .tpticon-plus::before {
  content: "\ea4b"; }

.tpt-frontend .tpticon-question::before {
  content: "\ea4c"; }

.tpt-frontend .tpticon-question-circle::before {
  content: "\ea4d"; }

.tpt-frontend .tpticon-question-circle-o::before {
  content: "\ea4e"; }

.tpt-frontend .tpticon-reply::before {
  content: "\ea4f"; }

.tpt-frontend .tpticon-search::before {
  content: "\ea50"; }

.tpt-frontend .tpticon-shopping-cart::before {
  content: "\ea51"; }

.tpt-frontend .tpticon-standards::before {
  content: "\ea52"; }

.tpt-frontend .tpticon-star::before {
  content: "\ea53"; }

.tpt-frontend .tpticon-star-o::before {
  content: "\ea54"; }

.tpt-frontend .tpticon-sticky-note-o::before {
  content: "\ea55"; }

.tpt-frontend .tpticon-tag::before {
  content: "\ea56"; }

.tpt-frontend .tpticon-tags::before {
  content: "\ea57"; }

.tpt-frontend .tpticon-teach-for-justice-badge::before {
  content: "\ea58"; }

.tpt-frontend .tpticon-th-large::before {
  content: "\ea59"; }

.tpt-frontend .tpticon-thumbs-o-up::before {
  content: "\ea5a"; }

.tpt-frontend .tpticon-thumbs-up::before {
  content: "\ea5b"; }

.tpt-frontend .tpticon-times::before {
  content: "\ea5c"; }

.tpt-frontend .tpticon-times-circle::before {
  content: "\ea5d"; }

.tpt-frontend .tpticon-trash-o::before {
  content: "\ea5e"; }

.tpt-frontend .tpticon-twitter::before {
  content: "\ea5f"; }

.tpt-frontend .tpticon-twitter-square::before {
  content: "\ea60"; }

.tpt-frontend .tpticon-user::before {
  content: "\ea61"; }

.tpt-frontend .tpticon-users::before {
  content: "\ea62"; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Anchor {
    font-weight: 400;
    cursor: pointer; }
    .tpt-frontend .Anchor:hover {
      text-decoration: underline; }
    .tpt-frontend .Anchor--undecorated:hover {
      text-decoration: none; }
    .tpt-frontend .Anchor--green {
      color: #067c64; }
      .tpt-frontend .Anchor--green:hover {
        color: #067c64; }
    .tpt-frontend .Anchor--black {
      color: #000000; }
    .tpt-frontend .Anchor--gray {
      color: #777777; }
      .tpt-frontend .Anchor--gray:hover {
        color: #000000; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .Button {
    border-radius: 3px;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
    line-height: 1;
    cursor: pointer;
    font-weight: 600;
    font-family: Proxima Nova, "Helvetica Neue", Helvetica, Arial, sans-serif;
    text-align: center; }
    .tpt-frontend .Button:disabled {
      background: #dddddd;
      border: 1px solid transparent;
      color: #777777;
      cursor: not-allowed; }
      .tpt-frontend .Button:disabled:hover {
        background: #dddddd;
        border: 1px solid transparent;
        color: #777777; }
  .tpt-frontend .Button--primary {
    background: #0aa96c;
    border: 1px solid transparent;
    color: #ffffff; }
    .tpt-frontend .Button--primary:hover {
      background: #07794d; }
  .tpt-frontend .Button--small {
    padding: 3px 6px;
    font-size: 14px;
    line-height: 1.5; }
    .tpt-frontend .Button--small .Button__iconLeft {
      margin-right: 6px; }
    .tpt-frontend .Button--small .Button__iconRight {
      margin-left: 6px; }
  .tpt-frontend .Button--primaryLink, .tpt-frontend .Button--whiteLink {
    background-color: transparent;
    color: #777777; }
    .tpt-frontend .Button--primaryLink:hover, .tpt-frontend .Button--whiteLink:hover {
      text-decoration: underline;
      background-color: transparent; }
  .tpt-frontend .Button--fullWidth {
    width: 100%; }
  .tpt-frontend .Button--medium {
    padding: 12px 18px;
    font-size: 16px; }
    .tpt-frontend .Button--medium .Button__iconLeft {
      margin-right: 12px; }
    .tpt-frontend .Button--medium .Button__iconRight {
      margin-left: 12px; }
  .tpt-frontend .Button--link {
    display: inline-block; }
  .tpt-frontend .Button--primaryOutline {
    background: transparent;
    border: 1px solid #067c64;
    color: #067c64; }
    .tpt-frontend .Button--primaryOutline:hover {
      border-color: #044b3d;
      color: #044b3d; }
  .tpt-frontend .Button--primaryLink {
    color: #067c64; }
  .tpt-frontend .Button--secondary {
    border: 1px solid transparent;
    background: #cccccc;
    color: #222222; }
    .tpt-frontend .Button--secondary:hover {
      background: #5e5e5e;
      color: #ffffff; }
  .tpt-frontend .Button--secondaryOutline {
    background: #ffffff;
    border: 1px solid #dddddd;
    color: #666666; }
    .tpt-frontend .Button--secondaryOutline:hover {
      border-color: #c4c4c4;
      color: #090909; }
  .tpt-frontend .Button--tertiary {
    border: 1px solid transparent;
    background: transparent;
    color: #067c64; }
  .tpt-frontend .Button--white {
    background: #ffffff;
    border: 1px solid transparent;
    color: inherit; }
    .tpt-frontend .Button--white:hover {
      background: rgba(0, 0, 0, 0.4);
      color: #ffffff; }
  .tpt-frontend .Button--whiteOutline {
    border: 1px solid #ffffff;
    background: transparent;
    color: #ffffff; }
    .tpt-frontend .Button--whiteOutline:hover {
      border-color: rgba(0, 0, 0, 0.4); }
  .tpt-frontend .Button--red {
    background: #c73833;
    border: 1px solid transparent;
    color: #ffffff; }
    .tpt-frontend .Button--red:hover {
      background: #9e2d29; }
  .tpt-frontend .Button--redLink {
    background-color: transparent;
    color: #c73833; }
    .tpt-frontend .Button--redLink:hover {
      text-decoration: underline;
      background-color: transparent; }
  .tpt-frontend .Button--gray {
    border: 1px solid transparent;
    background-color: #cccccc;
    color: #666666; }
    .tpt-frontend .Button--gray:hover {
      background-color: gray(dark);
      color: #ffffff; }
  .tpt-frontend .Button--grayLink {
    border: none;
    background-color: #ffffff;
    color: #777777; }
    .tpt-frontend .Button--grayLink:hover {
      color: #666666; }
  .tpt-frontend .Button--grayOutline {
    border: 1px solid #dddddd;
    background-color: #ffffff;
    color: #777777; }
    .tpt-frontend .Button--grayOutline:hover {
      border-color: #222222;
      color: #222222; }
  .tpt-frontend .Button--highlight {
    background-color: #2d9cdb;
    color: #ffffff; }
    .tpt-frontend .Button--highlight:hover {
      background-color: #0a71b4; }

.tpt-frontend {
  /**
 * This file includes tpt-frontend design tokens, SCSS variables, and mixins
 * that are frequently imported by individual component '.scss.' files.
 *
 * To keep component CSS bundle sizes small, settings should not include
 * any SCSS that outputs actual CSS - e.g. classes or @font-face rules
 */
  /**
 * Design tokens are the primitive UI properties (colors, fonts, borders, etc)
 * that represent the base look and feel of Core Marketplace.
 *
 * These scss files get automatically generated with `yarn run build:designTokens`
 *
 * NOTE: as we integrate the new Assembly design-system npm package into
 * tpt-frontend (See https://teacherspayteachers.atlassian.net/browse/WEB-2938),
 * these will likely get replaced by the design tokens in the package.
 */
  /**
 * Below are global SCSS variables used by UI components
 *
 * These vars differ from design-tokens in that they are specific
 * to tpt-frontend, whereas design tokens are high-level variables
 * used across all TpT frontends.
 *
 * You can think of design-tokens as the styling "options", whereas
 * the variables below apply the options in product contexts.
 */
  /**
 * Variables for "legacy", non-responsive PHP pages
 *
 * You can find the legacy Bootstrap grid configuration variables in
 * `src/shared/components/Grid/Grid.scss`
 */
  /**
 * "One-off" variables are variables that are likely introduced
 * to fit product needs, but may not yet be considered acceptable
 * for wide-spread use across the application.
 *
 * We recommend first using design token variables defined in
 * 'src/shared/styles/_design-tokens.scss'.
 *
 * Consult your nearest product designer for advice, but
 * we aim to keep this file as small as possible.
 */
  /**
  * clearfix allows a container to wrap its floated children.
  * Without a clearfix, a container does not wrap around its
  * floated children and collapses, just as f its floated
  * children were positioned absolutely.
  * See https://css-tricks.com/snippets/css/clear-fix/
  */ }
  .tpt-frontend .ShareToDrive {
    margin-bottom: 12px; }

